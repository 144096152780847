import { Typography } from "@material-ui/core"
import React from "react"

const SubcategoryBottomText = ({ text, title }) => {
  return (
    <div
      style={{
        borderTop: "1px solid #E3E3EA",
        paddingTop: "28px",
        textAlign: "justify",
      }}
    >
      <h2>{title}</h2>
      <Typography
        style={{
          fontSize: "14px",
        }}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      ></Typography>
    </div>
  )
}

export default SubcategoryBottomText
